:root {
    --roundRotate: 60deg;
    --roundTime: 40s;
    --numSize: 28px;
    --roleSize: 13px;
    --round1Width: 15.5%;
    --round2Width: 20px;
    --borderWidth: 3px
}
@media screen and (max-width: 1530px) {
    :root {
           --numSize: 30px; 
           --round1Width: 15%;
           /* --round1Width: 800px; */
       }
   }

@media screen and (max-width: 1366px) {
    :root {
        --numSize: 20px;
        --roleSize: 12px;
        /* --round2Width: 20pxs */
       
    }
}

@media screen and (max-width: 767px) {
    :root {
        /* --round1Width: 20%; */
        --round2Width: 15px;
        --borderWidth: 2px
    }

    .num,.role {
        zoom:.7}

    .role {
        font-weight: bold
    }
}

.main7 .inner {padding-top: 5%;}
.main7 .mod {padding-top: 16%;}
.main7 .post-71{position:relative;z-index: 10;top: 50%;transform: translateY(-52%);pointer-events:none;}
.main7 .post-71 .tt::before {opacity: 0.2;} 
.main7 .post-71 .tt{z-index: 9;width: 314px;height: 314px;}
.main7 .post-71 .tt .tit{ width: 200px;height: 200px; }
.main7 .post-71 .tt .tit::before {animation-name:none;top: -20px;right: -20px;bottom: -20px;left: -20px;}
.main7 .post-71 .tt .tit2 {width: 300px;height: 300px;}
.main7 .post-71 .tt .tit .title{font-size:36px;line-height:1.25;letter-spacing:5px;}
.main7 .post-71 .con {padding-top:0}


#round {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 9;
}

#round .roundContent {
    position: relative;
    overflow: hidden;
    perspective: 2200px;
    perspective-origin: center 55%;
}
#round .roundContent:after{
    content:'';
    position:absolute;
    left:-100%;
    top:0;
    right:-100%;
    bottom:0;
    z-index:100;
    background-image: linear-gradient(#50aeff,#2f85d8d9,#2a71c814,rgb(38 99 189 / 0%));
    pointer-events: none;
    opacity:0;
    transition-duration: 10s;
    transition-delay: 1400ms;
}
.wp7-8.showdiv #round .roundContent:after{opacity:1}

#round .roundContent .round {
    position:relative;
    z-index:9;
    padding-top: 70%;
    border: var(--borderWidth) solid #3077cb;
    border-radius: 50%;
    width: 70%;
    margin: auto;
    transform-style: preserve-3d;
    /* transform: rotateX(70deg) rotateZ(40deg); */
    animation: rotateRound var(--roundTime) linear infinite;
    animation-play-state: running;
}

#round .roundContent .round#round_1 {
    border: var(--borderWidth) dashed #3077cb;
    width: 90%;
    margin: 5%;
    --roundTime: 30s;
    padding-top: 90%;
}

#round .roundContent .round#round_2 {
    position: absolute;
    z-index: 10;
    --roundWidth: var(
    --round1Width);
    left:15%;
    top:15%;
    bottom:15%;
    right:15%;
}

#round .roundContent .round#round_2 .itemContent>div .con {
    background-image: url(./img/student.svg);
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat
}

#round .roundContent .round#round_2 .itemContent>div:nth-child(2) .con {
    background-image: url(./img/college.svg)
}

#round .roundContent .round#round_2 .itemContent>div:nth-child(3) .con {
    background-image: url(./img/master.svg)
}

#round .roundContent .round#round_2 .itemContent>div:nth-child(4) .con {
    background-image: url(./img/speciality.svg)
}

#round .roundContent .round#round_2 .itemContent>div:nth-child(5) .con {
    background-image: url(./img/research.svg)
}

#round .roundContent .round#round_3 {
    position: absolute;
    border: var(--borderWidth) solid rgba(48,119,203,.2);
    --roundTime: 50s;
    left:20%;
    top:20%;
    right:20%;
    bottom:20%;
    width: 60%;
    padding-top: 60%;
}

#round .roundContent .round#round_1,
#round .roundContent .round#round_3 {
    --roundWidth: var(--round2Width);
    animation-direction:reverse;
}

#round .roundContent .round#round_1 .itemContent>div,
#round .roundContent .round#round_3 .itemContent>div {
    animation-direction:reverse;
    background-color: rgba(48,119,203,.2)
}

#round .roundContent .round#round_1 .itemContent>div .con,
#round .roundContent .round#round_3 .itemContent>div .con {
    width: 40%;
    margin: 30%;
    background-color: #3077cb;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
}

#round .roundContent .round#round_1 .itemContent>div .title,
#round .roundContent .round#round_3 .itemContent>div .title {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    line-height:2;
    color: #00549b;
    white-space: nowrap;
    zoom:.8
}

#round .roundContent .round .itemContent {transform-style: preserve-3d;position: absolute;left:0;top:0;right:0;bottom:0;}

#round .roundContent .round .itemContent>div {
    position: absolute;
    width: var(--roundWidth);
    background-color: #ebf7ff;
    border-radius: 50%;
    padding-top: var(--roundWidth);
    animation: rotateItem var(--roundTime) linear infinite;
    margin-bottom: calc(var(--roundWidth)/-2);
    margin-left: calc(var(--roundWidth)/-2)
}

#round .roundContent .round#round_2 .itemContent>div {
    padding-top:inherit;
    animation:inherit;
    margin-bottom: inherit;
    margin-left: inherit;
    border-radius:0;
    background-color:transparent;
    transform-style: preserve-3d;
}
#round .roundContent .round#round_2 .itemContent>div .box {
    width:100%;
    background-color: #ebf7ff;
    animation: rotateItem2 var(--roundTime) linear infinite;
    border-radius: 50%;
    padding-top: 100%;
    margin-bottom: calc(var(--roundWidth)/-2);
    margin-left: calc(var(--roundWidth)/-2);
    animation-play-state: running;
}

#round .roundContent .round .itemContent>div:nth-child(1) {left: 50%;bottom: 0;}
#round .roundContent .round .itemContent>div:nth-child(2) {left: 4%; bottom: 70%;}
#round .roundContent .round .itemContent>div:nth-child(3) {left: 96%; bottom: 70%;}

#round .roundContent .round#round_1 .itemContent>div:nth-child(4),
#round .roundContent .round#round_3 .itemContent>div:nth-child(4),
#round .roundContent .round#round_1 .itemContent>div:nth-child(5),
#round .roundContent .round#round_3 .itemContent>div:nth-child(5) {display:none;}

#round .roundContent .round#round_2 .itemContent {transform: rotateY(90deg);}
#round .roundContent .round#round_2 .itemContent>div:nth-child(1) {transform: translate3d(-80px, 60px, 0px) ;}
#round .roundContent .round#round_2 .itemContent>div:nth-child(2) {left:50%;bottom: 50%;transform: translate3d(-80px, 264px, -490px);}
#round .roundContent .round#round_2 .itemContent>div:nth-child(3) {left:50%;bottom: 50%;transform: translate3d(-80px, -300px, -353px) ;}
#round .roundContent .round#round_2 .itemContent>div:nth-child(4) {left:50%;bottom: 50%;transform: translate3d(-80px, -310px, 353px);}
#round .roundContent .round#round_2 .itemContent>div:nth-child(5) {left:50%;bottom: 50%;transform: translate3d(-80px, 210px, 500px) ;}

@media screen and (max-width: 1530px) {
    #round .roundContent .round#round_2 .itemContent>div:nth-child(1) {transform: translate3d(-46px, 60px, 0px) ;}
    #round .roundContent .round#round_2 .itemContent>div:nth-child(2) {transform: translate3d(-46px, 274px, -349px);}
    #round .roundContent .round#round_2 .itemContent>div:nth-child(3) {transform: translate3d(-46px, -300px, -241px);}
    #round .roundContent .round#round_2 .itemContent>div:nth-child(4) {transform: translate3d(-46px, -310px, 159px);}
    #round .roundContent .round#round_2 .itemContent>div:nth-child(5) {transform: translate3d(-46px, 210px, 373px);}
}

@media screen and (max-width: 1366px) {
    #round .roundContent .round#round_2 .itemContent>div:nth-child(1) {transform: translate3d(-38px, 60px, 0px);}
    #round .roundContent .round#round_2 .itemContent>div:nth-child(2) {transform: translate3d(-38px, 134px, -329px);}
    #round .roundContent .round#round_2 .itemContent>div:nth-child(3) {transform: translate3d(-38px, -203px, -239px);}
    #round .roundContent .round#round_2 .itemContent>div:nth-child(4) {transform: translate3d(-38px, -200px, 254px);}
    #round .roundContent .round#round_2 .itemContent>div:nth-child(5) {transform: translate3d(-38px, 256px, 287px);}
}

#round .roundContent .round .itemContent>div .item {
    display: flex;
    width: 90%;
    padding-top:90%;
    margin: 5%;
    border-radius: 50%;
    background-color: #fff;
    position:relative;
}

#round .roundContent .round .itemContent>div .con {
    position:absolute;
    margin:auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    z-index: 20;
    border-radius: 50%;
    left: 15px;
    top: 15px;
    right: 15px;
    bottom: 15px;
    background-color: #fff;
    box-shadow: 2px 4px 10px rgba(33, 150, 243, 0.1);
}

#round .roundContent .round .itemContent>div .con .num {
    font-size: var(--numSize);
    font-weight: 600;
    color: #004083;
    margin-bottom:8px;
}

#round .roundContent .round .itemContent>div .con .role {
    font-size: var(--roleSize);
    color: #333
}

@keyframes rotateRound {
    from {
        transform: rotateX(65deg) rotateZ(0deg);
    }
    to {
        transform: rotateX(65deg) rotateZ(360deg);
    }
}

@keyframes rotateItem {
    from {
        transform: rotateX(-90deg) rotateY(0deg) scaleY(1.1);
    }
    to {
        transform: rotateX(-90deg) rotateY(360deg) scaleY(1.1);
    }
}

@keyframes rotateItem2 {
    from {
        transform: rotateX(0deg) rotateZ(295deg) rotateY(-90deg) ;
    }
    to {
        transform: rotateX(360deg) rotateZ(295deg) rotateY(-90deg);
    }
}